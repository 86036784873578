<template>
	<div class="user-management">
		<h1>User Management</h1>
		<!-- GET USER FORM -->
		<AddUserForm v-if="can(getAuthData, 'create_global_user')" @newUserAdded="refreshUsersList"></AddUserForm>
		<!-- GET MAIN CLIENTS LIST -->
		<UsersList :key="usersListKey"></UsersList>
	</div>
	<!-- end container -->
</template>
<script>
import AddUserForm from "../../components/forms/AddUserForm.vue"
import UsersList from "../../components/lists/UsersList.vue"
import {mapGetters} from "vuex"

export default {
	name: "userManagement",
	data() {
		return {
			usersListKey: 1,
		}
	},
	components: {
		AddUserForm,
		UsersList,
	},
	methods: {
		refreshUsersList() {
			this.usersListKey++
		},
		newUserAdded() {
			console.log("new user added")
		},
	},
	computed: {
		...mapGetters("auth", ["getAuthData"]),
	},
}
</script>
